import { AnyAction } from 'redux';

export enum KeySetterActionType {
    SET_POPUPKEY = 'SET_POPUPKEY',
    REMOVE_POPUPKEY = 'REMOVE_POPUPKEY',
    SET_POPUPKEYNAME = 'SET_POPUPKEYNAME',
    REMOVE_POPUPKEYNAME = 'REMOVE_POPUPKEYNAME',
    // New action type for removing the key
}

export interface SetKeyAction extends AnyAction {
    type: KeySetterActionType.SET_POPUPKEY;
    key: any;
}

export interface RemoveKeyAction extends AnyAction {
    type: KeySetterActionType.REMOVE_POPUPKEY;
}

export interface SetKeyNameAction extends AnyAction {
    type: KeySetterActionType.SET_POPUPKEYNAME;
    name: any;
}

export interface RemoveKeyNameAction extends AnyAction {
    type: KeySetterActionType.REMOVE_POPUPKEYNAME;
}

export type KeySetterAction = SetKeyAction | RemoveKeyAction | RemoveKeyNameAction | SetKeyNameAction; // Update to include remove key action

export interface KeySetterState {
    key: any;
    name: any;
}
