import { Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import { ProductReviewRating } from 'SourceComponent/Product/Product.component';
import ProductGallery from 'SourceComponent/ProductGallery';
import { CmsBlock } from 'SourceRoute/Checkout/Checkout.component';
import NoMatchHandler from 'SourceRoute/NoMatchHandler';
import {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductInformation,
    ProductLinks,
    ProductPageComponent as SourceProductPageComponent,
    ProductReviewForm,
    ProductReviews,
    ProductTabs,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { ProductPageTabs } from 'SourceRoute/ProductPage/ProductPage.config';
import { ProductPageTab } from 'SourceRoute/ProductPage/ProductPage.type';
import { LinkedProductType } from 'SourceStore/LinkedProducts/LinkedProducts.type';
import { ReactElement } from 'SourceType/Common.type';

// import dog_tab_img from '../../style/icons/dog_tab_img.png';
import minus from '../../style/icons/minus.svg';
import plus from '../../style/icons/plus.svg';

import './ProductPage.override.style';

export {
    ProductReviews,
    ProductTabs,
    ProductAttributes,
    ProductReviewForm,
    ProductLinks,
    ProductInformation,
    Popup,
    ProductActions,
};

/** @namespace TrouperPwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    state = {
        activeAccordion: 'productTabs',
    };

    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ ProductPageTabs.INFORMATION ]: {
            name: __('Description'),
            shouldTabRender: (): boolean => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Specifications'),
            shouldTabRender: (): boolean => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Nutritional information'),
            // Return true since we always show 'Add review' button
            shouldTabRender: (): boolean => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: (key: string): ReactElement => this.renderNutrintionalInfoTab(key),
        },
    };

/* eslint-disable */
    toggleAccordion = (section: string) => {
        this.setState((prevState) => ({
            // @ts-ignore
            activeAccordion: prevState.activeAccordion === section ? null : section,
        }));
    };

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
        } = this.props;

        return (
                <div block="ProductPage" elem="ProductActions">
                    <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                        <ProductActions
                          getLink={ getLink }
                          product={ dataSource }
                          parameters={ parameters }
                          areDetailsLoaded={ areDetailsLoaded }
                          setActiveProduct={ setActiveProduct }
                        />
                    </Suspense>
                </div>
        );
    }


    renderProductDesktopMainData(): ReactElement {
        return (
            <>
                { this.renderProductName() }
            </>
        );
    }

    scrollToTarget = () => {
        const targetElement = document.getElementById('scrollToReviewSection');
        if (targetElement) {
          const topPosition = targetElement.getBoundingClientRect().top;
          window.scrollTo({
            top: topPosition - 150,
            // behavior: 'smooth',
          });
        }
      };

       renderRatingSummary(): ReactElement {
            const {
                dataSource: {
                    review_summary: {
                        rating_summary,
                        review_count,
                    } = {},
                },
            } = this.props;

            console.log(this.props, "dataSourcedataSource");
            
    
            if (!rating_summary) {
                return null;
            }
    
            return (
                <div 
                block="ProductPage" elem="ProductTopReviewSection"
                >
<div style={{borderRight:"1px solid #B8B7B5",paddingRight:"12px"}}>
                <Suspense fallback={ null }>
                    <ProductReviewRating
                      summary={ rating_summary || 0 }
                      count={ review_count }
                    />
                </Suspense>
                </div>
                <div
                style={{borderRight:"1px solid #B8B7B5",paddingRight:"12px"}}
                block="ProductPage" elem="ProductTopReviewCount"
                >
                    {`${review_count} Reviews`}
                </div>
                <div
                block="ProductPage" elem="ProductTopReviewCount"
                style={{cursor:"pointer"}}
                onClick={() => this.scrollToTarget()}
                >Write a Review</div>
                </div>
            );
        }

    renderProductName(): ReactElement {
        const { dataSource: { name }, dataSource } = this.props;

        const brand = dataSource?.attributes?.brand;
    
        if (!brand?.attribute_value || !brand?.attribute_options) {
            return null;
        }

        const brandLabel = brand.attribute_options[brand.attribute_value]?.label;

        return (
            <>
                <h1 block="ProductPage" elem="Title" itemProp="name">
                    { name }
                </h1>
                    <div 
                    block="ProductPage" elem="ProductTopBy"
                    >By: <span>{ brandLabel }</span> </div>
                    { this.renderRatingSummary() }
                    </>
        );
    }

    renderProductActionsHeader() {
        const {
            isMobile,
        } = this.props;

        return (
                <div block="ProductPage" elem="ProductActions">
                 { !isMobile && this.renderProductDesktopMainData() }
                </div>
        );
    }

    renderProductTabs(): ReactElement {
        const tabs = this.shouldTabsRender();

        if (!tabs) {
            return null;
        }

        return (
                <Suspense fallback={ <div /> }>
                    <ProductTabs tabs={ tabs } />
                </Suspense>
        );
    }


      renderProductAttributesTab(key: string): ReactElement {
            const {
                activeProduct,
                areDetailsLoaded,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductAttributes
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            );
        }

       renderProductReviewsTab(key: string): ReactElement {
            const {
                dataSource,
                areDetailsLoaded,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductReviews
                      product={ dataSource }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            );
        }

        renderNutrintionalInfoTab(key: string): ReactElement {
            const {
                dataSource,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: dataSource?.attributes?.nutritional_info?.attribute_value || '',
                        }}
                    />
                </Suspense>
            );
        }

    renderAdditionalSections(): ReactElement {
        const { activeAccordion } = this.state;

        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
            <ContentWrapper>

            <div
              onClick={ () => this.toggleAccordion('productTabs') }
              style={ { display: 'flex', justifyContent: 'space-between', borderTop:"1px solid #B8B7B5",paddingTop:"32px" } }
            >
                <h3>About this product</h3>
                <div>
{ ' ' }
{ activeAccordion === 'productTabs' ? <img style={{height:"32px", width:"32px"}} src={minus} alt="minus" /> : <img style={{height:"32px", width:"32px"}} src={plus} alt="plus" /> }
{ ' ' }
                </div>
            </div>
            </ContentWrapper>
            { activeAccordion === 'productTabs' && (
                <ContentWrapper>
                {/* <div
                block="ProductPage" elem="ProductTabImg" 
                > */}

                <div>

                    {this.renderProductTabs()}
                </div>
                    {/* <div>
                        <img src={dog_tab_img} alt="dog_tab_img" />
                    </div> */}
                {/* </div> */}
                </ContentWrapper>
            ) }

{ ' ' }
{ /* { this.renderProductTabs() } */ }
                    <Suspense fallback={ null }>
                        <ProductLinks
                          linkType={ LinkedProductType.RELATED }
                          title={ __('Recommended for you') }
                          areDetailsLoaded={ areDetailsLoaded }
                        />
                    </Suspense>
                    <Suspense fallback={ null }>
                        <ProductLinks
                          linkType={ LinkedProductType.UPSELL }
                          title={ __('You might also like') }
                          areDetailsLoaded={ areDetailsLoaded }
                        />
                    </Suspense>

                    <ContentWrapper>
                        <CmsBlock identifier="home-recommended-products" />
                        </ContentWrapper>
                        <ContentWrapper>
                        <CmsBlock identifier="usp-section" />
                        </ContentWrapper>
                        
                        <div>

                        {this.renderProductReviewsTab("key")}
                        </div>
            </>
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
            hideReviews
        } = this.props as any;
        console.log(this.props, 'useEmptyGallerySwitcher');

        return (
            <>
                    <ProductGallery
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      isWithEmptySwitcher={ useEmptyGallerySwitcher }
                      showLoader={ isVariant }
                      // @ts-ignore
                      hideReviews = { hideReviews }
                    />
                    { this.renderProductActions() }
            </>
        );
    }

    render(): ReactElement {
        const {
            hideReviews,
        } = this.props as any;
        return (
                <NoMatchHandler>
                    <main
                      block="ProductPage"
                      aria-label="Product page"
                      itemScope
                      itemType="http://schema.org/Product"
                    >
                         <ContentWrapper>

                            { this.renderProductActionsHeader() }
                         </ContentWrapper>

                        <ContentWrapper
                          wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                          label={ __('Main product details') }
                        >
                            { this.renderProductPageContent() }
                        </ContentWrapper>
                        {!hideReviews && (
    <>
        {this.renderAdditionalSections()}
        {this.renderReviewPopup()}
    </>
)}
                        {/* { this.renderAdditionalSections() }
                        { this.renderReviewPopup() } */}
                    </main>
                </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
