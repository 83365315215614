import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { ReactElement } from 'SourceType/Common.type';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';

import ProductPage from './ProductPage.component';
import {
    ProductPageComponentProps,
    ProductPageContainerComponentPropKeys,
} from './ProductPage.type';
/** @namespace TrouperPwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace TrouperPwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});
/** @namespace TrouperPwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer{
    containerProps(): Pick<ProductPageComponentProps, ProductPageContainerComponentPropKeys> {
        const { isMobile, areReviewsEnabled, hideReviews } = this.props as any;
        const { parameters } = this.state;
        const { location } = history;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            hideReviews,
        };
    }

    render(): ReactElement {
        return (
                <ProductPage
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
