import { KeySetterAction, KeySetterActionType, KeySetterState } from './RegisterPopup.type';

export const initialState: KeySetterState = {
    key: {},
    name: {}, // Initial state with an empty key
};

/**
 * Reducer to handle the `SET_POPUPKEY` and `REMOVE_KEY` actions.
 * @param state - Current state of the KeySetter.
 * @param action - Action dispatched to update the state.
 * @returns {KeySetterState} - The updated state with the new key or reset key.
 * @namespace TrouperPwa/Store/RegisterPopup/Reducer/RegisterPopupReducer */
export const RegisterPopupReducer = (
    state = initialState,
    action: KeySetterAction,
): KeySetterState => {
    switch (action.type) {
    case KeySetterActionType.SET_POPUPKEY:
        return {
            ...state,
            key: action.key, // Set the new key
        };

    case KeySetterActionType.REMOVE_POPUPKEY:
        return {
            ...state,
            key: {}, // Reset the key to an empty string
        };

    case KeySetterActionType.SET_POPUPKEYNAME:
        return {
            ...state,
            name: action.name, // Set the new key
        };

    case KeySetterActionType.REMOVE_POPUPKEYNAME:
        return {
            ...state,
            name: {}, // Reset the key to an empty string
        };

    default:
        return state;
    }
};

export default RegisterPopupReducer;
