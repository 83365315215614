/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import deleteIcon from '../../style/icons/deleteIcon.svg';

import './ResetButton.style';
import { ResetButtonComponentProps } from 'SourceComponent/ResetButton/ResetButton.type';

/** @namespace TrouperPwa/Component/ResetButton/Component */
export class ResetButtonComponent extends PureComponent<ResetButtonComponentProps> {
    static defaultProps: Partial<ResetButtonComponentProps> = {
        mix: {},
    };

    /* eslint-disable */

    __construct(props: ResetButtonComponentProps): void {
        super.__construct?.(props);
    }

    render(): ReactElement {
        const { mix, isContentFiltered, onClick } = this.props;

        if (!isContentFiltered) {
            return null;
        }

        return (
            <span
              block="ResetButton"
              mix={ mix }
            >
                <button
                  onClick={ onClick }
                >
                <img block="ResetButton" elem="DeleteButtonFilter" src={ deleteIcon } alt="deleteIcon" />
                </button>
            </span>
        );
    }
}

export default ResetButtonComponent;
