import { Field, Mutation } from '@tilework/opus';

/** @namespace TrouperPwa/Query/Registration/Query */

export class RegistrationQuery {
    getSendOtpMutation(username: string): Mutation<'sendOtp',
    { otp: string; status: string; secret: string; is_customer: boolean }> {
        return new Mutation<'sendOtp', { otp: string; status: string; secret: string; is_customer: boolean }>('sendOtp')
            .addArgument('username', 'String!', username)
            .addField('otp')
            .addField('status')
            .addField('secret')
            .addField('is_customer');
    }

    verifyOtpMutation(secret: string, otp: string): Mutation<'verifyOtp', { status: boolean; message: string }> {
        return new Mutation<'verifyOtp', { status: boolean; message: string }>('verifyOtp')
            .addArgument('otp', 'String!', otp)
            .addArgument('secret', 'String!', secret)
            .addField('status')
            .addField('message');
    }

    customerLoginMutation(email: string): Mutation<'customerLogin',
    { token: string; profile_update: boolean; redirect_url: string; is_customer: boolean }> {
        return new Mutation<'customerLogin',
        { token: string; profile_update: boolean; redirect_url: string; is_customer: boolean }>('customerLogin')
            .addArgument('email', 'String!', email)
            .addField('token')
            .addField('profile_update')
            .addField('redirect_url')
            .addField('is_customer');
    }

    _getCustomerField(): Field<'customer', any> {
        return new Field<'customer', any>('customer')
            .addFieldList(this._getCustomerFields());
    }

    _getCustomerInfoFields(): any {
        return [
            new Field<'primary_phone_number', string>('primary_phone_number'),
            new Field<'secondary_phone_number', string>('secondary_phone_number'),
            new Field<'profile_update', string>('profile_update'),
            new Field<'token', string>('token'),
        ];
    }

    _getRegionField(): Field<'region', any> {
        return new Field<'region', any>('region')
            .addFieldList(this._getRegionFields());
    }

    _getRegionFields(): any {
        return [
            new Field<'region_code', string>('region_code'),
            new Field<'region', string>('region'),
        ];
    }

    _getAddressField(): Field<'addresses', any> {
        return new Field<'addresses', any>('addresses')
            .addFieldList(this._getAddressesFields());
    }

    _getAddressesFields(): any {
        return [
            new Field<'firstname', string>('firstname'),
            new Field<'middlename', string>('middlename'),
            new Field<'lastname', string>('lastname'),
            new Field<'street', string>('street'),
            new Field<'city', string>('city'),
            new Field<'postcode', string>('postcode'),
            new Field<'country_code', string>('country_code'),
            new Field<'telephone', string>('telephone'),
        ];
    }

    _getCustomerInfoField(): Field<'custom_info', any, true> {
        return new Field<'custom_info', any, true>('custom_info', true)
            .addFieldList(this._getCustomerInfoFields());
    }

    _getCustomerQueryInfoFields(): any {
        return [
            new Field<'primary_phone_number', string>('primary_phone_number'),
            new Field<'secondary_phone_number', string>('secondary_phone_number'),
            new Field<'token', string>('token'),
            new Field<'profile_update', string>('profile_update'),
        ];
    }

    _getCustomerQueryInfoField(): Field<'custom_info', any, true> {
        return new Field<'custom_info', any, true>('custom_info', true)
            .addFieldList(this._getCustomerInfoFields());
    }

    _getCustomerFields(): any {
        return [
            new Field<'firstname', string>('firstname'),
            new Field<'lastname', string>('lastname'),
            new Field<'email', string>('email'),
            this._getCustomerInfoField(),
        ];
    }

    _getCustomerQueryField(): any {
        return [
            new Field<'firstname', string>('firstname'),
            new Field<'lastname', string>('lastname'),
            new Field<'email', string>('email'),
            new Field<'middlename', string>('middlename'),
            new Field<'suffix', string>('suffix'),
            this._getCustomerQueryInfoField(),
            this._getAddressField(),
        ];
    }

    getCreateAccountMutation(options: any): Mutation<'createCustomer', { customer: any }> {
        // const { customer, password } = options;

        return new Mutation<'createCustomer', { customer: any }>('createCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }

    getCustomerDetails(): any {
        return new Field('customer')
            .addFieldList([
                new Field<'firstname', string>('firstname'),
                new Field<'middlename', string>('middlename'),
                new Field<'lastname', string>('lastname'),
                new Field<'suffix', string>('suffix'),
                new Field<'email', string>('email'),
                new Field('custom_info').addFieldList([
                    'primary_phone_number',
                    'secondary_phone_number',
                    'profile_update',
                ]),
                new Field('addresses').addFieldList([
                    new Field<'firstname', string>('firstname'),
                    new Field<'middlename', string>('middlename'),
                    new Field<'lastname', string>('lastname'),
                    new Field<'street', string>('street'),
                    new Field<'city', string>('city'),
                    new Field<'postcode', string>('postcode'),
                    new Field<'country_code', string>('country_code'),
                    new Field<'telephone', string>('telephone'),
                    new Field('region').addFieldList([
                        'region_code',
                        'region',
                    ]),
                ]),
            ]);
    }
}

export default new RegistrationQuery();
