/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { store } from '@scandipwa/scandipwa/src/util/Store';
import { PureComponent, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import noSearchImage from 'src/style/icons/no-search-result-page.png';

import Loader from 'Component/Loader';
import ProductListPage from 'Component/ProductListPage';
import SlickSliderComponent from 'Component/SlickSlider';
import { TopBrandSliderSettings, TopCategoriesSliderSettings } from 'Route/CategoryPage/CategoryPage.constants';
import { ReactElement } from 'Type/Common.type';
// import { scrollToTop } from 'Util/Browser';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';
import history from 'Util/History';
import { IndexedProduct } from 'Util/Product/Product.type';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import CmsBlock from '../CmsBlock/CmsBlock.container';
import { OBSERVER_THRESHOLD } from './ProductList.config';
import { PageProps, ProductListComponentProps } from './ProductList.type';

import './ProductList.style';

export const Pagination = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays-misc" */ 'Component/Pagination'
));

/**
 * List of category products
 * @class ProductList
 * @namespace TrouperPwa/Component/ProductList/Component
 */
export class ProductListComponent extends PureComponent<ProductListComponentProps> {
    static defaultProps: Partial<ProductListComponentProps> = {
        mix: {},
        title: '',
        isInfiniteLoaderEnabled: false,
        isPaginationEnabled: false,
        selectedFilters: {},
        isLoading: false,
        updatePage: noopFn,
        totalPages: 1,
        loadPage: noopFn,
        loadPrevPage: noopFn,
        currentPage: 1,
        isShowLoading: false,
        isVisible: true,
        isWidget: false,
    };

    observer: IntersectionObserver | null = null;

    nodes: Record<string, HTMLElement | null> = {};

    observedNodes: HTMLElement[] = [];

    pagesIntersecting: number[] = [];

    /* eslint-disable */
    componentDidUpdate(prevProps: ProductListComponentProps): void {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget) {
            // scrollToTop();
        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }

    componentWillUnmount(): void {
        if (this.observer && this.observer.disconnect) {
            this.observer.disconnect();
        }

        this.observer = null;
    }

    observePageChange(): void {
        const { updatePage, isLoading } = this.props;
    
        if (isLoading) {
            this.pagesIntersecting = [];
        }
    
        if (!this.observer && 'IntersectionObserver' in window) {
            this.observer = new IntersectionObserver((entries) => {
                const { currentPage, totalPages } = this.props;
    
                entries.forEach(({ target, isIntersecting }) => {
                    const page = +(Object.keys(this.nodes).find((node) => this.nodes[node] === target) || 0);
    
                    if (isIntersecting && page === currentPage && currentPage < totalPages) {
                        updatePage(currentPage + 1);
                    }
                });
            }, {
                rootMargin: '0px 0px 100px 0px', // Adjust to trigger loading before reaching the bottom
                threshold: 0.1,
            });
        }
    
        this.updateObserver();
    }
    

    updateObserver(): void {
        const currentNodes = Object.values(this.nodes);

        if (!this.observer || currentNodes.length <= 0) {
            return;
        }

        currentNodes.forEach((node) => {
            if (node && !this.observedNodes.includes(node)) {
                this.observer?.observe(node);
                this.observedNodes.push(node);
            }
        });

        this.observedNodes = this.observedNodes.reduce((acc: HTMLElement[], node) => {
            if (!currentNodes.includes(node)) {
                this.observer?.unobserve(node);
            } else {
                acc.push(node);
            }

            return acc;
        }, []);
    }

    _getThreshold(): number[] {
        const hundredPercent = 100;

        return Array.from(
            { length: (hundredPercent / OBSERVER_THRESHOLD) + 1 },
            (_, i) => i * (OBSERVER_THRESHOLD / hundredPercent),
        );
    }

    renderLoadButton(): ReactElement {
        const {
            isShowLoading,
            isInfiniteLoaderEnabled,
            loadPrevPage,
        } = this.props;

        if (!isShowLoading || !isInfiniteLoaderEnabled) {
            return null;
        }

        return (
            <div
              block="ProductList"
              elem="LoadButton"
              role="button"
              tabIndex={ 0 }
              onKeyUp={ loadPrevPage }
              onClick={ loadPrevPage }
            >
                { __('Load previous') }
            </div>
        );
    }

    noSearchImageFound(): ReactElement {
        return (
          <div block = "ProductList" elem='noSearchImage'>
            <img src={noSearchImage} alt="noSearchImage" />
          </div>
        )
      }

      initializeCarousel = () => {
        const timeoutNumber = 1000;
        setTimeout(() => {
          const customImageSliders = document.querySelectorAll('.top-categories-section .category-slider, .top-categories-section .brand-slider');
  
          customImageSliders.forEach((customImageSlider) => {
              const customImageSliderItems = customImageSlider.querySelectorAll('.category-item, .brand-item'); // account for category-item or brand-item
          
              if (customImageSliderItems.length > 0) {
                  // Determine which settings to use based on slider type
                  const isCategorySlider = customImageSlider.classList.contains('brand-slider');
                  const sliderSettings = isCategorySlider ? TopBrandSliderSettings : TopCategoriesSliderSettings;
          
                  ReactDOM.render(
                      <Provider store={store}>
                          <SlickSliderComponent
                              {...sliderSettings} // Apply the appropriate settings based on slider type
                              categoryItems={Array.from(customImageSliderItems)} // Convert NodeList to Array
                          />
                      </Provider>,
                      customImageSlider // Render the component in the current slider element
                  );
              }
          });
          
            
        }, timeoutNumber);
    };

      renderTopCategoriesBlock() {
        return (
          <div
            block="CategoryPage"
            elem="ProductListWrapper"
            mods={{ staticBlock:true}}
          >
            <>
            <CmsBlock identifier="home-top-categories" />
            <CmsBlock identifier="Static_Popular_Brands" />
            { this.initializeCarousel() }
            </>
               
          </div>
        )
      }

      rendernoPageContent(): ReactElement {
        return (
          <>
          <div
            block="CategoryPage"
            elem="ProductListWrapperImageBlock"
          >
          </div>
          
          {this.renderTopCategoriesBlock()}
     
          </>
        )
      }

    renderNoProducts(): ReactElement {
        const {pathname} = history.location;
        let searchName = "";
        if(pathname && pathname.includes("/")){
            let splittedArray = pathname.split("/").filter(item => item !== "");
            searchName = splittedArray[splittedArray.length-1];

        }
        return (
            <>
            <div block="ProductList">
                {this.noSearchImageFound()}
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __("Sorry, we couldn't find any results for ") }<span block="ProductList"
                  elem="ProductsMissingDynamicText"> "{searchName}"</span></h2>
                    <p>{ __('Double check the spelling, or try searching some other keywords') }</p>
                </div>
                
            </div>
            {this.rendernoPageContent()}
            </>
        );
    }

    renderPages(): ReactElement {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
        } = this.props;

        if (isLoading) {
            return this.renderPage();
        }

        const pageRenders = Object.entries(pages).map(this.renderProductPage.bind(this));

        if (isVisible && isInfiniteLoaderEnabled) { // add placeholders to the end of pages if needed
            const key = Math.max(...(Object.keys(pages) as unknown as number[])) + 1; // the key should match next page key

            pageRenders.push(this.renderPage({ key }));
        }

        if (isVisible && isInfiniteLoaderEnabled) {
            // Add placeholder for loading state
            const loadingKey = -1;
            pageRenders.push(this.renderPage({ key: loadingKey }));
        }

        return pageRenders;
    }
    /* eslint-disable */

    _processProps(props: Partial<PageProps>): Partial<PageProps> {
        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            return props;
        }

        // there must be no more then one page per screen
        // if the "isInfiniteLoaderEnabled" is false
        const { key, ...restProps } = props;

        return { ...restProps, key: 0 };
    }

    renderPage(props: Partial<PageProps> = {}): ReactElement {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
            banners,
        } = this.props;

        const {
            items = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              banners={ banners }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
            />
        );
    }

    renderProductPage([k, items = []]: [k: string, items: IndexedProduct[]]): ReactElement {
        const { selectedFilters } = this.props;
        const key = Number(k);

        const pageNumber = key;

        return this.renderPage({
            selectedFilters,
            pageNumber,
            items,
            key,
            wrapperRef: (node: HTMLElement | null) => {
                this.nodes[ pageNumber ] = node;
            },
        });
    }

    renderPagination(): ReactElement {
        const {
            isLoading,
            totalPages,
            isPaginationEnabled,
        } = this.props;

        if (!isPaginationEnabled) {
            return null;
        }

        return (
            <Suspense fallback={ <div /> }>
                <Pagination
                  isLoading={ isLoading }
                  totalPages={ totalPages }
                />
            </Suspense>
        );
    }

    renderTitle(): ReactElement {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h2>{ title }</h2>
        );
    }

    renderLayoutChangingLoader(): ReactElement {
        const {
            productListLoaderRef,
        } = this.props;

        const width = productListLoaderRef?.current?.offsetWidth;

        if (productListLoaderRef?.current) {
            CSS.setVariable(productListLoaderRef, 'product-list-loader-width', `${width}px`);
        }

        return (
            <div block="ProductList" elem="LayoutChangingLoader" ref={ productListLoaderRef }>
                <div block="ProductList" elem="LayoutChangingLoaderWrapper">
                    <Loader />
                </div>
            </div>
        );
    }

    render(): ReactElement {
        const {
            totalPages,
            isLoading,
            mix,
        } = this.props;

        if (!isLoading && totalPages === 0) {
            setLoadedFlag();

            return this.renderNoProducts();
        }

        return (
            !isLoading && totalPages > 0 ? (
                <div
                    block="ProductList"
                    mods={{ isLoading }}
                    mix={mix}
                >
                    {this.renderLayoutChangingLoader()}
                    {this.renderTitle()}
                    {this.renderLoadButton()}
                    {this.renderPages()}
                    {this.renderPagination()}
                </div>
            ) : null
        );
        
    }
}

export default ProductListComponent;
