import { ComponentType, Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Link from 'Component/Link';
import {
    CarouselScroll,
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import { ReactElement } from 'SourceType/Common.type';

import share_icon from '../../style/icons/share_icon.svg';
import wishlist_yellow_icon from '../../style/icons/wishlist_yellow_icon.svg';
import { ProductGalleryComponentProps } from './ProductGallery.type';

import './ProductGallery.style';

export {
    CarouselScroll,
    VideoPopup,
};

/** @namespace TrouperPwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    render(): ReactElement {
        const { hideReviews, location } = this.props as any;
        console.log(this.props, 'hideReviewshideReviews');

        return (
                <div block="ProductGallery" ref={ this.galleryRef }>
                    { this.renderSlider() }
                    { this.renderAdditionalPictures() }
                    <Suspense fallback={ null }>
                        <VideoPopup />
                    </Suspense>
                    <div style={ { position: 'absolute', right: '-50px' } }>
                    <img
                      style={ { height: '40px', width: '40px', display: 'block' } }
                      src={ wishlist_yellow_icon }
                      alt="wishlist_yellow_icon"
                    />
                    <img
                      style={ { height: '40px', width: '40px', marginTop: '7px' } }
                      src={ share_icon }
                      alt="share_icon"
                    />
                    </div>
                    { hideReviews
                    && (
<div style={ { bottom: '0px', position: 'absolute', left: '0px' } }>
                                    <Link to={ location?.pathname }>View More Details</Link>
</div>
                    ) }
                </div>
        );
    }
}

export default withRouter(
    ProductGalleryComponent as unknown as ComponentType<
    RouteComponentProps & ProductGalleryComponentProps
    >,
);
