import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import { store } from '@scandipwa/scandipwa/src/util/Store';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import SlickSliderComponent from 'Component/SlickSlider/SlickSlider.component';
import { TopCategoriesSliderSettings } from 'Route/CategoryPage/CategoryPage.constants';

import BrandsQuery from '../../query/AllBrands.query';
import CmsBlock from '../CmsBlock/CmsBlock.container';
import { BrandDetailComponentProps, BrandDetailsComponentState } from './BrandDetail.type';

import './BrandDetail.style';
/** @namespace TrouperPwa/Component/BrandDetail/Component */
export class BrandDetailComponent extends PureComponent<BrandDetailComponentProps> {
    state: BrandDetailsComponentState = {
        brandData: null,
    };

    componentDidMount = () => {
        if (location.pathname.includes('/brand/')){
            this.getBrandsListDetails();
        }
    };

    getBrandsListDetails = async () => {
        const locationPath = location.pathname.split('brand/');
        const queryResult = BrandsQuery.AnotherQueryInstance.brandById(`brand/${locationPath[1]}`);
        const response = await fetchQuery(queryResult) as any;

        if (response){
            this.setState({ brandData: response.brandById });
        }
    };

    initializeCarousel = () => {
        const timeoutNumber = 1000;
        setTimeout(() => {
            const customImageSliders = document.querySelectorAll('.top-categories-section .category-slider');

            customImageSliders.forEach((customImageSlider) => {
                const customImageSliderItems = customImageSlider.querySelectorAll('.category-item'); // account for category-item or brand-item

                if (customImageSliderItems.length > 0) {
                    // eslint-disable-next-line react/no-deprecated
                    ReactDOM.render(
                      <Provider store={ store }>
                          <SlickSliderComponent
                            { ...TopCategoriesSliderSettings } // eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            categoryItems={ Array.from(customImageSliderItems) } // Convert NodeList to Array
                          />
                      </Provider>,
                      customImageSlider, // Render the component in the current slider element
                    );
                }
            });
        }, timeoutNumber);
    };

    renderSubcategoryFields() {
        return (
            <div block="Plp_slider">
                <CmsBlock identifier="home-top-categories" />
                { /* eslint-disable-next-line */ }
                <>{ this.initializeCarousel() }</>
            </div>

        );
    }

    render() {
        const { brandData } = this.state;
        const {
            label, image, description, top_cms_block_id,
        } = brandData || {} as any;

        return (
            <div block="BrandDetail">
               { label && (
                <div block="BrandDetail" elem="BrandName">
                    <div block="BrandDetail" elem="BannerImage">
                        <img src={ image } alt={ image } />
                    </div>
                    <div block="ContentWrapper">
                        <h1>{ label }</h1>
                        <div block="BrandDetail" elem="BrandDescription">
                            <p>
                            { description }
                            </p>
                        </div>
                        { this.renderSubcategoryFields() }
                        <div block="BrandDetail" elem="BrandImageSection">
                            <CmsBlock identifier={ top_cms_block_id } />
                        </div>
                    </div>
                </div>
               ) }

            </div>
        );
    }
}

export default BrandDetailComponent;
