/* eslint-disable */
import { Location } from 'history';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import BlogDetailPage from 'Component/BlogDetailPage/BlogDetailPage';
import BlogListingPosts from 'Component/BlogListingPosts/BlogListingPosts';
import { RouterComponent as SourceRouterComponent, withStoreRegex } from 'SourceComponent/Router/Router.component';
import { HistoryState } from 'Util/History/History.type';

import { SERVICE_URLS } from '../../route/Brands/Brands.config';
import UrlRewrites from '../../route/UrlRewrites/UrlRewrites.container';
import { RouterItemType, RouterSwitchItemType,RouterBeforeItemType } from './Router.config';
import { RouterComponentProps } from './Router.type';
import React from 'react';
import { lowPriorityLazy } from '@scandipwa/scandipwa/src/util/Request/LowPriorityRender';

/* eslint-disable max-len */
export const BrandsListingPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "services" */ 'Route/Brands'))as any;
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../../route/CreateAccount'))as any;
export const Header = lowPriorityLazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ '../Header/Header.container'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../../route/MyAccount'));

/** @namespace TrouperPwa/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    constructor(props: RouterComponentProps) {
        super(props); // Call the parent constructor
        this[RouterItemType.BEFORE_ITEMS_TYPE].filter(item => item.name !== "HEADER")
        .push(
            {
                component: <Header />,
                position: 21,
                name: RouterBeforeItemType.HEADER as any,
            }
        )
        this[RouterItemType.SWITCH_ITEMS_TYPE].push(
            {
                component: <Route
                  path={ withStoreRegex('/brands') }
                  render={ ({ match }) => <BrandsListingPage match={ match } /> }
                />,
                position: 15,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                name: RouterSwitchItemType.BRANDS as any,
            },
            {
                // @ts-ignore Due to problems with extended components
                component: <Route path={ withStoreRegex('/customer/account/dashboard/') } render={ () => <CreateAccountPage /> } />,
                position: 62,
                name: RouterSwitchItemType.CREATE_ACCOUNT as any,
            },
            {
                component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ ({ match }) => <MyAccount match={ match } /> } />,
                position: 76,
                name: RouterSwitchItemType.MY_ACCOUNT as any,
            },
            {
                component: <Route exact path={ withStoreRegex('/blog') } render={ () => <BlogListingPosts /> } />,
                position: 97,
                name: RouterSwitchItemType.BLOG_PAGE as any,
            },
            {
                component: <Route path={ withStoreRegex('/blog/:blogDetail') } render={ () => <BlogDetailPage /> } />,
                position: 98,
                name: RouterSwitchItemType.BLOGDEtail_PAGE as any, 
            },
            {
                component: <Route render={ ({ match }) => <UrlRewrites match={ match } location={ location as unknown as Location<HistoryState> } /> } />,
                position: 1000,
                name: RouterSwitchItemType.URL_REWRITES as any,
            },
        );
    }
}

export default RouterComponent;
/* eslint-disable */