import { Field } from 'Util/Query';

/**
 * @namespace TrouperPwa/Query/BlogDetailPage/Query */
export class BlogDetailPageQuery {
    blogPost(id: number | string) {
        return new Field('blogPost')
            .addArgument('id', 'String', id.toString())
            .addFieldList([
                'post_id',
                'meta_title',
                'meta_description',
                'title',
                'filtered_content',
                'featured_image',
                'featured_img_alt',
                new Field('author').addFieldList([
                    'author_id',
                    'name',
                    'author_url',
                ]),
                'post_url',
                'creation_time',
                'reading_time',
                new Field('tags').addFieldList([
                    'tag_id',
                    'title',
                    'tag_url',
                ]),
                new Field('categories').addFieldList([
                    'category_id',
                    'title',
                    'category_url',
                ]),
                new Field('related_posts').addFieldList([
                    'post_id',
                    'title',
                    'post_url',
                    'featured_list_image',
                    'featured_list_img_alt',
                    'publish_time',
                    'short_filtered_content',
                    new Field('hotspot').addFieldList([
                        'id',
                        'name',
                        'top',
                        'left',
                        'price',
                        'special_price',
                        'url',
                    ]),
                ]),
            ]);
    }
}

export default BlogDetailPageQuery;
