import { KeySetterActionType, RemoveKeyAction, SetKeyAction } from './CmsBlock.type';

/**
 * Action to set the `key` value in the state.
 * @param {string} key - The key to be set in the state.
 * @returns {SetKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/CmsBlock/Action/setKey */
export const setKey = (key: string): SetKeyAction => ({
    type: KeySetterActionType.SET_KEY,
    key,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/CmsBlock/Action/removeKey */
export const removeKey = (): RemoveKeyAction => ({
    type: KeySetterActionType.REMOVE_KEY,
});
