import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';
import { RootState } from 'Util/Store/Store.type';

import { ProductGalleryComponentContainerPropKeys, ProductGalleryComponentProps } from './ProductGallery.type';
/** @namespace TrouperPwa/Component/ProductGallery/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace TrouperPwa/Component/ProductGallery/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    /* eslint-disable */
    // @ts-ignore
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace TrouperPwa/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {

     containerProps(): Pick<ProductGalleryComponentProps, ProductGalleryComponentContainerPropKeys> {
            const { activeImage, isZoomEnabled, isImageZoomPopupActive } = this.state;
            const {
                product: { id },
                isMobile,
                isWithEmptySwitcher,
                showLoader,
                hideReviews,
            } = this.props as any;
    
            return {
                gallery: this.getGalleryPictures(),
                productName: this._getProductName(),
                activeImage,
                isZoomEnabled,
                productId: id,
                isMobile,
                isImageZoomPopupActive,
                sliderRef: this.sliderRef,
                isWithEmptySwitcher,
                showLoader,
                hideReviews
            };
        }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
