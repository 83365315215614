import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import BrandDetail from './BrandDetail.component';
import {
    BrandDetailContainerFunctions,
    BrandDetailContainerProps,
    BrandDetailMapStateProps,
} from './BrandDetail.type';

/** @namespace TrouperPwa/Component/BrandDetail/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): BrandDetailMapStateProps => ({
    loadedBrandId: state.UrlRewritesReducer.urlRewrite.id,
    categoryData: state.CategoryReducer.category as any,
    ...state,
});

/** @namespace TrouperPwa/Component/BrandDetail/Container/mapDispatchToProps */
export const mapDispatchToProps = (): any => ({

});

/** @namespace TrouperPwa/Component/BrandDetail/Container */
export class BrandDetailContainer extends PureComponent<BrandDetailContainerProps> {
    containerFunctions: BrandDetailContainerFunctions = {
        // getData: this.getData.bind(this)
    };

    // Adjust keys for return
    containerProps():any {
        const { loadedBrandId, categoryData } = this.props;

        return {
            // isDisabled: this._getIsDisabled()
            loadedBrandId,
            categoryData,
        };
    }

    render() {
        return (
            <BrandDetail
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrandDetailContainer);
