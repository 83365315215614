import { AnyAction } from 'redux';

export enum KeySetterActionType {
    SET_KEY = 'SET_KEY',
    REMOVE_KEY = 'REMOVE_KEY', // New action type for removing the key
}

export interface SetKeyAction extends AnyAction {
    type: KeySetterActionType.SET_KEY;
    key: any;
}

export interface RemoveKeyAction extends AnyAction {
    type: KeySetterActionType.REMOVE_KEY;
}

export type KeySetterAction = SetKeyAction | RemoveKeyAction; // Update to include remove key action

export interface KeySetterState {
    key: any;
}
